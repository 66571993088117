html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.cursor-pointer {
  cursor: pointer;
}
@media print {
  .pagebreak {
    page-break-before: always;
  }


  // div.page-footer, div.footer-info {
  //     display: block;
  // }
}

// div.page-footer {
//   text-align: center;
//   height: 50px;
//   font-size: 10px;
//   opacity: 0.8;
//   position: fixed;
//   bottom: 0;
//   width: 100%;
// }

// div.page-footer p {
//   margin: 0;
// }

// tfoot.report-footer {
//   display: table-footer-group;
// }

// div.footer-info, div.page-footer {
//   display: none;
//   height: 60px;
// }

table.report-container {
  // page-break-after: always;
  width: 100%;
}

thead.report-header {
  display: table-header-group;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px; 
}

.skeleton {
  height: 80vh;
}

.align-center {
  width: 100%;
  z-index: 0;
}
.card-container {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
}

:root {
  --background-gradient: linear-gradient(178deg, #ffff33 10%, #3333ff);
  --gray: #34495e;
  --darkgray: #2c3e50;
}

select {
  /* Reset Select */
  appearance: none;
  outline: 10px red;
  border: 1px solid #ebc43a;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;
  color: #ebc43a;
  // background-color: var(--darkgray);
  background-image: none;
  cursor: pointer;
  border-radius: 8px;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  border-radius: 0.25em;
  overflow: hidden;
}
/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #ebc43a;
  transition: 0.25s all ease;
  pointer-events: none;
  color: #ffff;
}

.down_note {
  display: flex;
  justify-content: center;
}

.btn-primary {
  background-color: #ebc43a;
  border: #ebc43a;
}

.btn-primary:hover {
  background-color: #e3bb2a !important;
  transition: all 0.2s ease-in-out;
}

.btn-primary:focus {
  background-color: #e3bb2a !important;
  outline: none !important;
  box-shadow: none;
}

.btn-primary:disabled {
  background-color: #ebc43a !important;
  opacity: 0.65;
  border: #ebc43a;
}


.text-primary {
  color: #ebc43a !important;
}

/* src/App.css */
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Gaya tombol pagination */
.pagination .page-link {
  // background-color: blue; /* Ganti dengan warna yang Anda inginkan */
  color: #ebc43a; /* Warna teks */
  // border-color: blue; /* Warna border (jika diperlukan) */
}

/* Gaya tombol pagination yang aktif */
.pagination .page-item.active .page-link {
  background-color: #ebc43a; /* Ganti dengan warna yang Anda inginkan untuk tombol aktif */
  border-color: #ebc43a; /* Ganti dengan warna border yang sesuai (jika diperlukan) */
}

@media (min-width: 768px) and (max-width: 991px) {
  .card-counter {
    width: 100%;
  }
}

.filter-button{
  margin-top: 12px;
}

@media (min-width: 992px) {
  .card-counter {
    width: 20rem !important; /* Atur lebar dalam piksel sesuai kebutuhan Anda */
  }
}
@media (min-width: 768px) {
  .split-form {
    width: 50% !important; /* Atur lebar dalam piksel sesuai kebutuhan Anda */
  }
  .filter-button{
    margin-top: 0px;
  }
}

#mapdiv {
  width: 100%;
  height: 500px;
  background-color: white;
  border-radius: 20px;
  margin-bottom: 20px;
}
#chartdiv {
  width: 100%;
  height: 500px;
  background-color: white;
  border-radius: 20px;
}

.sj-table {
  margin-top: 80px;
}

@media (min-width: 992px) {
  .chart {
    display: flex;
  }
  #mapdiv {
    width: 120%;
    margin-right: 20px;
    margin-bottom: 0px;
  }
  #chartdiv {
    width: 80%;
  }
  .sj-table {
    margin-top: 120px;
  }
}

.image-preview img {
  transition: transform 0.3s;
}

.image-preview img:hover {
  transform: scale(1.2);
}


.login-head-image {
  width: 50%;
  display: flex;
  margin: 0 auto;
}

.bg-login{
  background-color: white;
}

@media only screen and (min-width: 768px) {
  .bg-login {
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #ebc43a;
  }

  .login-form {
    width: 40%;
    padding: 80px;
    background-color: white;
  }

  .login-form-image {
    width: 60%;
  }

  .login-head {
    margin-top: 100px;
  }

  .login-head-image {
    width: 150px;
    margin-left: -10px;
  }

  .login-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    display: block;
  }
}